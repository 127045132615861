body {
  font-family: "Karla", Arial, sans-serif;
  line-height: 1.8;
  font-size: 16px;
  font-weight: 400;
  background: #fafafa;
  color: gray;
}

body.menu-show {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
}

#colorlib-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#colorlib-wrapper>div {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

a {
  color: #CA82F8;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

a:hover {
  text-decoration: underline;
  color: #CA82F8;
}

a:focus,
a:active {
  outline: none;
}

p,
span {
  margin-bottom: 1.5em;
  font-weight: 400;
  font-family: "Karla", Arial, sans-serif;
}

ul,
ol {
  display: block;
  margin-bottom: 1.5em;
}

span {
  font-size: 18px;
  color: rgba(114, 114, 114, 0.8);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Playfair Display", Georgia, serif;
  margin: 0 0 30px 0;
}

::-webkit-selection {
  color: #fcfcfc;
  background: #b7c2c2;
}

::-moz-selection {
  color: #fcfcfc;
  background: #b7c2c2;
}

::selection {
  color: #fcfcfc;
  background: #b7c2c2;
}

#colorlib-page {
  position: relative;
  -webkit-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.menu-show #colorlib-page {
  -webkit-transform: scale(0.99);
  -moz-transform: scale(0.99);
  -ms-transform: scale(0.99);
  -o-transform: scale(0.99);
  transform: scale(0.99);
  position: relative;
}

.menu-show #colorlib-page:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 768px) {
  #colorlib-page {
    padding-top: 5em;
  }
}

#colorlib-main-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 4em 40px 40px 40px;
  width: 360px;
  height: 100%;
  background: white;
  z-index: 1002;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  overflow-y: scroll;
}

@media screen and (max-width: 768px) {
  #colorlib-main-nav {
    width: 300px;
  }
}

.menu-show #colorlib-main-nav {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

#colorlib-main-nav ul {
  padding: 0;
  margin: 0 0 3em 0;
}

@media screen and (max-width: 768px) {
  #colorlib-main-nav ul {
    padding: 20px 0 0 0;
  }
}

#colorlib-main-nav ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
}

#colorlib-main-nav ul li a {
  display: block;
  color: black;
  font-size: 14px;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  #colorlib-main-nav ul li a {
    font-size: 16px;
  }
}

#colorlib-main-nav ul li a:hover,
#colorlib-main-nav ul li a:active,
#colorlib-main-nav ul li a:focus {
  color: black;
  outline: none;
  text-decoration: none;
}

#colorlib-main-nav ul li.active a {
  color: #CA82F8;
}

#colorlib-main-nav .form-group {
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 480px) {
  #colorlib-main-nav .form-group {
    width: 100%;
  }
}

#colorlib-main-nav .form-group input::-webkit-input-placeholder {
  color: #333333;
}

#colorlib-main-nav .form-group input::-moz-input-placeholder {
  color: #333333;
}

#colorlib-main-nav .form-group input::-ms-input-placeholder {
  color: #333333;
}

#colorlib-main-nav .form-group input::-o-input-placeholder {
  color: #333333;
}

#colorlib-main-nav .form-group input::input-placeholder {
  color: #333333;
}

#colorlib-main-nav #search {
  font-size: 14px;
  width: 100%;
  padding-right: 3em;
  border: none;
  border: 2px solid #f2f2f2;
  background: #fafafa;
  color: #333333 !important;
}

#colorlib-main-nav button {
  position: absolute;
  top: 0;
  right: -4px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  background: #CA82F8;
  color: #fff;
  border: none;
  padding: 16px;
}

#colorlib-main-nav #email {
  font-size: 18px;
  width: 100%;
  border: none;
  border: 2px solid #fff;
  background: #fff;
  color: #333333 !important;
}

#colorlib-main-nav .gallery {
  float: left;
  width: 50%;
  height: 150px;
  position: relative;
  display: table;
  z-index: 0;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  #colorlib-main-nav .gallery {
    width: 25%;
  }
}

@media screen and (max-width: 480px) {
  #colorlib-main-nav .gallery {
    width: 50%;
  }
}

#colorlib-main-nav .gallery:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  z-index: -1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

#colorlib-main-nav .gallery span {
  display: table-cell;
  vertical-align: middle;
  height: 150px;
  opacity: 0;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

#colorlib-main-nav .gallery span i {
  color: #fff;
  font-size: 20px;
}

#colorlib-main-nav .gallery:hover:after,
#colorlib-main-nav .gallery:focus:after {
  opacity: 1;
}

#colorlib-main-nav .gallery:hover span,
#colorlib-main-nav .gallery:focus span {
  opacity: 1;
}

header {
  padding: 2em 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  header {
    padding: 2em 0;
  }
}

#colorlib-services,
#colorlib-contact,
#colorlib-work,
#colorlib-blog,
#colorlib-testimony,
#colorlib-progress,
#colorlib-contact,
#colorlib-about {
  padding: 10em 0;
  clear: both;
  position: relative;
}

@media screen and (max-width: 768px) {

  #colorlib-services,
  #colorlib-contact,
  #colorlib-work,
  #colorlib-blog,
  #colorlib-testimony,
  #colorlib-progress,
  #colorlib-contact,
  #colorlib-about {
    padding: 5em 0;
  }
}

.rotate {
  position: absolute;
  top: .4em;
  left: -50px;
}

.rotate .heading {
  font-family: "Karla", Arial, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1em;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: left top 0;
  -moz-transform-origin: left top 0;
  -ms-transform-origin: left top 0;
  -o-transform-origin: left top 0;
  transform-origin: left top 0;
  position: relative;
}

.rotate .heading:after {
  position: absolute;
  top: 8px;
  right: -40px;
  content: '';
  background: #000;
  width: 40px;
  height: 1px;
}

.about-desc {
  padding-top: 3em;
}

.about-desc h2 {
  line-height: 1.3;
}

.about-desc h2 span {
  display: block;
  margin-bottom: 0;
  color: #000;
  font-size: 50px;
  font-family: "Playfair Display", Georgia, serif;
}

.about-desc .desc {
  padding-left: 6em;
  position: relative;
}

.about-desc .rotate {
  position: absolute;
  top: 7px;
  left: 30px;
}

.about-desc .rotate .heading {
  font-family: "Karla", Arial, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1em;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: left top 0;
  -ms-transform-origin: left top 0;
  transform-origin: left top 0;
  position: relative;
}

.about-desc .rotate .heading:after {
  position: absolute;
  top: 8px;
  right: -40px;
  content: '';
  background: #000;
  width: 40px;
  height: 1px;
}

.bold {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 250px;
  color: #f0f0f0;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .bold {
    font-size: 60px;
  }
}

.intro-heading {
  margin-bottom: 3em;
}

.intro-heading h2 {
  font-size: 34px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1.5em;
  color: #000;
  position: relative;
}

.intro-heading span {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px;
  margin-bottom: 10px;
  color: #bfbfbf;
  font-weight: 600;
}

.services-img,
.work-img,
.gallery {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.progress-head {
  font-size: 22px;
}

.progress {
  width: 150px;
  height: 150px;
  line-height: 150px;
  background: none;
  margin: 0 auto;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
}

.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 2px;
  border-style: solid;
  position: absolute;
  top: 0;
  border: 1px solid #000;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  -ms-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  -ms-transform-origin: center right;
  transform-origin: center right;
  -webkit-animation: loading-1 1.8s linear forwards;
  animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
  width: 85%;
  height: 85%;
  border-radius: 50%;
  font-size: 32px;
  line-height: 125px;
  text-align: center;
  position: absolute;
  top: 7.5%;
  left: 7.5%;
}

.progress.blue .progress-bar {
  border-color: #049dff;
}

.progress.blue .progress-value {
  color: #049dff;
}

.progress.blue .progress-left .progress-bar {
  -webkit-animation: loading-2 1.5s linear forwards 1.8s;
  animation: loading-2 1.5s linear forwards 1.8s;
}

.progress.yellow .progress-bar {
  border-color: #fdba04;
}

.progress.yellow .progress-value {
  color: #fdba04;
}

.progress.yellow .progress-left .progress-bar {
  -webkit-animation: loading-3 1s linear forwards 1.8s;
  animation: loading-3 1s linear forwards 1.8s;
}

.progress.pink .progress-bar {
  border-color: #ed687c;
}

.progress.pink .progress-value {
  color: #ed687c;
}

.progress.pink .progress-left .progress-bar {
  -webkit-animation: loading-4 0.4s linear forwards 1.8s;
  animation: loading-4 0.4s linear forwards 1.8s;
}

.progress.green .progress-bar {
  border-color: #1abc9c;
}

.progress.green .progress-value {
  color: #1abc9c;
}

.progress.green .progress-left .progress-bar {
  -webkit-animation: loading-5 1.2s linear forwards 1.8s;
  animation: loading-5 1.2s linear forwards 1.8s;
}

@-webkit-keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@-webkit-keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
  }
}

@-webkit-keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

@-webkit-keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@-webkit-keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(125deg);
    transform: rotate(125deg);
  }
}

@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(125deg);
    transform: rotate(125deg);
  }
}

@media only screen and (max-width: 990px) {
  .progress {
    margin-bottom: 20px;
  }
}

.services-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  width: 100%;
}

.services-flex .one-third {
  width: 55%;
  padding: 3em 2em 3em 0;
}

.services-flex .one-forth {
  width: 45%;
}

.services-flex .one-forth .one-third,
.services-flex .one-forth .one-forth {
  display: inline-block;
}

@media screen and (max-width: 768px) {

  .services-flex .one-forth .one-third,
  .services-flex .one-forth .one-forth {
    height: 400px;
  }
}

.services-flex .one-third,
.services-flex .one-forth {
  display: inline-block;
}

@media screen and (max-width: 768px) {

  .services-flex .one-third,
  .services-flex .one-forth {
    width: 100%;
    display: block;
  }
}

.services {
  margin-bottom: 4em;
}

.services h3 {
  font-family: "Karla", Arial, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
}

.services ul li {
  list-style: none;
  margin-bottom: 10px;
}

.services ul li::before {
  content: '\2022';
  color: #000;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.work-entry {
  display: block;
  margin-bottom: 5em;
  width: 100%;
  float: left;
}

.work-entry .work-img {
  width: 100%;
  height: 700px;
  margin-bottom: 40px;
  display: table;
  text-align: center;
  text-decoration: none;
}

.work-entry .work-img .display-t {
  display: table-cell;
  vertical-align: middle;
  height: 700px;
  position: relative;
  z-index: 0;
}

.work-entry .work-img .display-t:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.work-entry .work-img .display-t .work-name {
  display: inline-block;
  padding: 4em 3em;
  background: #fff;
  -webkit-box-shadow: 0px 2px 49px -4px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 2px 49px -4px rgba(0, 0, 0, 0.21);
  -ms-box-shadow: 0px 2px 49px -4px rgba(0, 0, 0, 0.21);
  -o-box-shadow: 0px 2px 49px -4px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 2px 49px -4px rgba(0, 0, 0, 0.21);
  z-index: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.work-entry .work-img .display-t .work-name h2 {
  margin: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.work-entry .work-img:hover .display-t:after {
  opacity: 1;
}

.work-entry .work-img:hover .display-t .work-name {
  padding: 4.5em 3.5em;
}

.work-entry .work-img:hover .display-t .work-name h2 {
  font-size: 34px;
}

@media screen and (max-width: 768px) {
  .work-entry .work-img {
    height: 400px;
  }

  .work-entry .work-img .display-t {
    height: 400px;
  }
}

.work-entry .desc .read {
  text-align: right;
}

.work-entry .desc .read a {
  color: #000;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 12px;
  position: relative;
}

.work-entry .desc .read a:after {
  position: absolute;
  top: 7px;
  left: -60px;
  right: 0;
  content: '';
  width: 40px;
  height: 1px;
  background: #000;
}

.article {
  display: block;
  margin-bottom: 6em;
}

.article .blog-img {
  margin-bottom: 50px;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.article .blog-img img {
  position: relative;
  max-width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

.article .blog-img .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.article .blog-img .link {
  position: absolute;
  bottom: 0;
  left: 90%;
  display: table;
  width: 100%;
  opacity: 0;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

.article .blog-img .link .read {
  display: table-cell;
  vertical-align: middle;
  font-family: "Karla", Arial, sans-serif;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 5px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0;
  -ms-transform-origin: 0;
  transform-origin: 0;
  position: relative;
}

.article .blog-img .link .read:after {
  position: absolute;
  top: 10px;
  left: -50px;
  content: '';
  background: #fff;
  width: 40px;
  height: 1px;
}

.article .desc {
  position: relative;
}

.article .desc .meta {
  position: absolute;
  top: -80px;
  left: 20px;
  background: #FFE9E3;
  color: #000;
  padding: 20px 10px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 4px;
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  -webkit-box-shadow: 0px 2px 49px -4px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 2px 49px -4px rgba(0, 0, 0, 0.21);
  -ms-box-shadow: 0px 2px 49px -4px rgba(0, 0, 0, 0.21);
  -o-box-shadow: 0px 2px 49px -4px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 2px 49px -4px rgba(0, 0, 0, 0.21);
}

.article .desc h2 {
  font-size: 24px;
}

.article .desc h2 a {
  text-decoration: none;
  color: #000;
}

.article:hover .meta {
  background: #fff;
}

.testimony blockquote {
  border: none;
}

.testimony blockquote p {
  font-family: "Playfair Display", Georgia, serif;
  color: #000;
  font-size: 20px;
  font-style: italic;
}

.testimony blockquote span {
  font-family: "Playfair Display", Georgia, serif;
  color: #000;
  font-size: 20px;
}

.f-entry {
  position: relative;
  margin-bottom: 40px;
}

.f-entry .desc h3 {
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 10px;
}

.f-entry .desc h3 a {
  color: #4d4d4d;
  text-decoration: none;
}

.f-entry .desc span {
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: block;
  margin-bottom: 10px;
}

.btn {
  margin-right: 4px;
  margin-bottom: 4px;
  font-size: 13px !important;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -ms-border-radius: 1px;
  border-radius: 1px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 8px 20px;
}

.btn.btn-md {
  padding: 8px 20px !important;
}

.btn.btn-lg {
  padding: 18px 36px !important;
}

.btn:hover,
.btn:active,
.btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary {
  background: #CA82F8;
  color: #fff;
  border: 2px solid #CA82F8;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background: #d49af9 !important;
  border-color: #d49af9 !important;
}

.btn-primary.btn-outline {
  background: transparent;
  color: #4d4d4d;
  border: 1px solid #d9d9d9;
}

.btn-primary.btn-outline:hover,
.btn-primary.btn-outline:focus,
.btn-primary.btn-outline:active {
  background: #CA82F8;
  color: #fff;
}

.btn-success {
  background: #5cb85c;
  color: #fff;
  border: 2px solid #5cb85c;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  background: #4cae4c !important;
  border-color: #4cae4c !important;
}

.btn-success.btn-outline {
  background: transparent;
  color: #5cb85c;
  border: 2px solid #5cb85c;
}

.btn-success.btn-outline:hover,
.btn-success.btn-outline:focus,
.btn-success.btn-outline:active {
  background: #5cb85c;
  color: #fff;
}

.btn-info {
  background: #5bc0de;
  color: #fff;
  border: 2px solid #5bc0de;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active {
  background: #46b8da !important;
  border-color: #46b8da !important;
}

.btn-info.btn-outline {
  background: transparent;
  color: #5bc0de;
  border: 2px solid #5bc0de;
}

.btn-info.btn-outline:hover,
.btn-info.btn-outline:focus,
.btn-info.btn-outline:active {
  background: #5bc0de;
  color: #fff;
}

.btn-warning {
  background: #f0ad4e;
  color: #fff;
  border: 2px solid #f0ad4e;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active {
  background: #eea236 !important;
  border-color: #eea236 !important;
}

.btn-warning.btn-outline {
  background: transparent;
  color: #f0ad4e;
  border: 2px solid #f0ad4e;
}

.btn-warning.btn-outline:hover,
.btn-warning.btn-outline:focus,
.btn-warning.btn-outline:active {
  background: #f0ad4e;
  color: #fff;
}

.btn-danger {
  background: #d9534f;
  color: #fff;
  border: 2px solid #d9534f;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
  background: #d43f3a !important;
  border-color: #d43f3a !important;
}

.btn-danger.btn-outline {
  background: transparent;
  color: #d9534f;
  border: 2px solid #d9534f;
}

.btn-danger.btn-outline:hover,
.btn-danger.btn-outline:focus,
.btn-danger.btn-outline:active {
  background: #d9534f;
  color: #fff;
}

.btn-outline {
  background: none;
  border: 2px solid gray;
  font-size: 16px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.row-padded-bottom {
  margin-bottom: 4em;
}

.col-pb-sm {
  margin-bottom: 5em !important;
}

.col-pb-sm:last-child {
  margin-bottom: 0;
}

.btn.with-arrow {
  position: relative;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.btn.with-arrow i {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -8px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.btn.with-arrow:hover {
  padding-right: 50px;
}

.btn.with-arrow:hover i {
  color: #fff;
  right: 18px;
  visibility: visible;
  opacity: 1;
}

.contact-info {
  margin-bottom: 4em;
  padding: 0;
}

.contact-info li {
  list-style: none;
  margin: 0 0 30px 0;
  position: relative;
  padding-left: 60px;
}

.contact-info li span {
  position: absolute;
  top: -0.3em;
  left: 0;
  width: 40px;
  height: 40px;
  display: table;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.contact-info li i {
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}

.contact-info li a {
  color: #CA82F8;
}

.form-control {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  border: none;
  border: transparent;
  font-size: 16px;
  font-weight: 300;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}

.form-control:focus,
.form-control:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: transparent;
}

input[type="text"] {
  height: 50px;
}

.form-group {
  margin-bottom: 30px;
}

.colorlib-social-icons a:hover,
.colorlib-social-icons a:focus {
  text-decoration: none !important;
}

.colorlib-social-icons a {
  font-size: 24px;
  color: #CA82F8;
  margin-left: 5px;
}

#footer {
  padding: 8em 0;
  display: block;
  width: 100%;
}

#footer h2 {
  color: #000;
  letter-spacing: 1px;
  font-weight: 300;
  margin-bottom: 2em;
}

#footer p:last-child {
  margin-bottom: 0;
}

#footer p.colorlib-social-icons a:hover,
#footer p.colorlib-social-icons a:focus {
  text-decoration: none !important;
}

#footer p.colorlib-social-icons i {
  font-size: 24px;
  color: #CA82F8;
}

#footer p .block {
  color: #1a1a1a;
  font-size: 14px;
}

#footer .subscribe .form-control {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  background: #fafafa;
  font-weight: 400;
  margin-bottom: 5px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

#footer .subscribe .form-control:focus,
#footer .subscribe .form-control:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 2px solid rgba(202, 130, 248, 0.8);
}

#footer .subscribe .btn-custom {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

.js .animate-box {
  opacity: 0;
}

.about-avatar-wrap {
  height: '100%' !important;
}

.about-avatar {
  display: block;
  margin: 9% auto;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
}

.header-contact {
  float: right;
  z-index: 1003;
  display: block;
  margin: 10px auto;
  cursor: pointer;
}

.navbar-brand-icon {
  max-width: 42px;
  max-height: 42px;
  margin: 6px auto;
}

.carousel-image {
  width: 100%;
  min-height: 360px;
  object-fit: scale-down;
}

.gd-carousel {
  position: unset;

  .react-multiple-carousel__arrow {
    position: absolute;
  }

  .react-multiple-carousel__arrow--left {
    left: calc(10% + 1px)
  }

  .react-multiple-carousel__arrow--right {
    right: calc(10% + 1px)
  }
}
